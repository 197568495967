import 'core-js/es/promise';

export default function loadPolyfills() {
  if (
    'Map' in window &&
    'forEach' in NodeList.prototype &&
    'startsWith' in String.prototype &&
    'endsWith' in String.prototype &&
    'includes' in String.prototype &&
    'includes' in Array.prototype &&
    'finally' in Promise.prototype &&
    'assign' in Object &&
    'entries' in Object &&
    'keys' in Object
  ) {
    return Promise.resolve();
  }
  return import(/* webpackChunkName: "polyfills" */ './lazy-load-polyfills');
}
